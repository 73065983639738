import React, { Component } from "react";

import Logo from "../../assets/images/kaaza.png"
import { Container } from "./styles"
import SwalTwo from "sweetalert2"
import { session } from "../../services/session.service"
import api from "../../services/api"
import { notification } from "antd";

class Login extends Component {
  state = {
    login: '',
    pass: '',
    disabled: false,
    recoverPassword: false
  }

  onSubmit = async () => {
    this.setState(state => ({ disabled: true }));

    if (this.state.recoverPassword) {
      api.post("/session/passRecover", {
        login: this.state.login
      }).then(response => {
        if (response.data.message) {
          SwalTwo.fire({
            icon: 'success',
            title: 'sucesso',
            text: response.data.message
          });
        }
      }).catch(error => {
        SwalTwo.fire({
          icon: 'error',
          title: 'Ops!',
          text: error.response.data.message
        });
      }).finally(() =>
        this.setState(state => ({ disabled: false }))
      );
      return;
    }

    session.login(this.state.login, this.state.pass)
      .then(async res => {
        const base64Url = res.data.token.split(".")[1];
        const base64 = atob(base64Url.replace("-", "+").replace("_", "/"));

        this.props.config.actions.unpackToken(res.data)
          .then(_ => {
            SwalTwo.fire({ title: "Seja bem-vindo!", icon: "success" })

            if (JSON.parse(base64).currentRole === "ADMIN") {
              this.props.history.push("/dashboard");
            } else {
              this.props.history.push("/dashboard/apartamentos");
            }
          });

        api.defaults.headers.authorization = `BEARER ${res.data.token}`;
      })
      .catch(error => {
        if (error.response && error.response.status === 401) {
          SwalTwo.fire({
            icon: 'error',
            title: 'Ops!',
            text: error.response.data.message
          })
        }
        return notification.error({
          message: 'Ops!',
          description:
            'Alguma coisa deu errado',
        });
      })
      .finally(() =>
        this.setState(state => ({ disabled: false })))
  }

  handleInput = (name, value) => {
    this.setState(state => ({ [name]: value }))
  }

  render() {
    return (
      <Container>
        <div className="content">
          <img src={Logo} alt="Kaaza" width={170} />
          <div className="content-inner">
            <div className="header bold center-align">
              {this.state.recoverPassword ? "Recuperar senha" : "Login"}
            </div>
            <form
              className="margin_2_top"
              onSubmit={(e) => this.state.disabled ? e.preventDefault() : (e.preventDefault(), this.onSubmit())}>
              <div className="input">
                <div className="image">
                  <i className="fas fa-user-circle"></i>
                </div>
                <input type="text" placeholder="email" onChange={e => this.handleInput('login', e.target.value)} value={this.state.login} />
              </div>
              {
                !this.state.recoverPassword
                  ? (
                    <div className="input margin_1_top">
                      <div className="image">
                        <i className="fas fa-lock"></i>
                      </div>
                      <input type="password" placeholder="password" onChange={e => this.handleInput('pass', e.target.value)} value={this.state.pass} />
                    </div>
                  ) : <></>
              }

              <div className="margin_2_top">
                <button type="submit" disabled={this.state.disabled}>
                  {this.state.disabled ? "PROCESSANDO..." : "ACESSAR"}
                </button>
              </div>
              <div className="recover-password">
                <button type="button" onClick={_ => this.setState((state) => ({
                  recoverPassword: !this.state.recoverPassword,
                  login: ''
                }))}>{this.state.recoverPassword ? "Fazer login" : "Esqueci minha senha"}</button>
              </div>
            </form>
          </div>
        </div>
      </Container>
    )
  }
}

export default Login
